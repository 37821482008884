import { updateProduct } from "../product/actions";
import {
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
} from "../product/actionType";
import axios from "./utils/axios";

const fetchUpdateProduct = (skuId, product) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_PRODUCT_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `api/report/update_sku/${skuId}/`,
        product,
        config
      );
      dispatch(updateProduct(data));
    } catch (error) {
      dispatch({
        type: UPDATE_PRODUCT_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchUpdateProduct;

import { Col, Row } from "react-bootstrap";
import { convertDate } from "./convertDate";

export default function ReportPart({ report }) {
  const {
    report_id,
    name,
    notes,
    order_number,
    ship_date,
    sku_loss,
    total_cost,
  } = report || {};

  //   const { sku, product, loss_quantity, unit_cost, line_total } = sku_loss || [];
  return (
    <div style={{ margin: "20px 50px" }}>
      <Row>
        <Col xs={12} md={6}>
          Report ID : {report_id}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          Ship Date : {convertDate(ship_date)}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          Order Number : {order_number}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          Customer Name : {name}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          Notes : {notes}
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <h4>Items Lost</h4>
        <table
          style={{
            width: "100%",
            fontSize: "12px",
            margin: "10px 20px",
            textAlign: "center",
          }}
        >
          <thead>
            <tr style={{ border: "1px solid black" }}>
              <th style={{ border: "1px solid black" }}>SKU</th>
              <th style={{ border: "1px solid black" }}>Product Name</th>
              <th style={{ border: "1px solid black" }}>Qty</th>
              <th style={{ border: "1px solid black" }}>Unit Cost</th>
              <th style={{ border: "1px solid black" }}>Line Total</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(sku_loss) ? (
              sku_loss?.map((i, index) => (
                <tr key={index} style={{ border: "1px solid black" }}>
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                  >
                    {i.sku}
                  </td>
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                  >
                    {i.product}
                  </td>
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                  >
                    {i.loss_quantity}
                  </td>
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                  >
                    ${i.unit_cost}
                  </td>
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                  >
                    ${i.line_total}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
        <br />
        <br />
        <Col xs={12} md={12} style={{ marginTop: "20px" }}>
          Total for Report {report_id} : ${total_cost}
        </Col>
      </Row>
    </div>
  );
}

import {
  ADD_REPORT_LOSS_SUCCESS,
  UPDATE_REPORT_LOSS_SUCCESS,
  VIEW_REPORT_LOSS_SUCCESS,
} from "./actionType";

export const addReportLoss = (data) => {
  return {
    type: ADD_REPORT_LOSS_SUCCESS,
    payload: data,
  };
};

export const viewReportLoss = (data) => {
  return {
    type: VIEW_REPORT_LOSS_SUCCESS,
    payload: data,
  };
};

export const updateReportLoss = (data) => {
  return {
    type: UPDATE_REPORT_LOSS_SUCCESS,
    payload: data,
  };
};

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import search from "../../assets/search.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import fetchAddProduct from "../../redux/thunk/fetchAddProduct";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import Success from "../ui/Success";
import axios from "../../redux/thunk/utils/axios";
import fetchUpdateProduct from "../../redux/thunk/fetchUpdateProduct";
export default function ManageReport() {
  const [skuId, setSkuID] = useState("");
  const [productName, setProductName] = useState("");
  const [cost, setCost] = useState("");
  const [type, setType] = useState("default");
  const [notification, setnotification] = useState(false);
  const [notification_msg, setnotification_msg] = useState("");
  let navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const addProduct = useSelector((state) => state.addProduct);
  const updateProduct = useSelector((state) => state.updateProduct);
  const dispatch = useDispatch();
  const { userInfo } = login || {};
  const { loading, success, isError, error } = addProduct || {};
  const {
    loading: updateLoading,
    isError: updateisError,
    error: updateError,
    success: updateSuccess,
  } = updateProduct || {};
  const reset = () => {
    setSkuID("");
    setProductName("");
    setCost("");
  };
  const submitHandler = (e) => {
    e.preventDefault();
    let product;
    product = {
      sku_id: skuId,
      product_name: productName,
      cost: cost,
    };
    setType("create");
    dispatch(fetchAddProduct(product));
    reset();
  };
  const updateHandler = (e) => {
    e.preventDefault();
    setType("update");
    let product;
    product = {
      product_name: productName,
      cost: cost,
    };
    dispatch(fetchUpdateProduct(skuId, product));
  };
  const searchHandler = (e) => {
    e.preventDefault();
    setType("search");
    axios
      .get(`api/report/get_sku/${skuId}/`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        const { product_name, cost } = res.data || {};
        setProductName(product_name);
        setCost(cost);
      })
      .catch((err) => {
        // console.log(err.response.data)
        setnotification(true);
        if (err?.response?.data.hasOwnProperty("error")) {
          setnotification_msg(err.response.data.error);
        } else {
          setnotification_msg("Unexpected error from api.");
        }
      });
  };
  let content;
  content =
    type === "create" ? (
      <div>
        {loading && <Loading />}
        {isError && <Error message={error} />}
        {success && <Success message="Product Create Successfully" />}
      </div>
    ) : type === "update" ? (
      <div>
        {updateLoading && <Loading />}
        {updateisError && <Error message={updateError} />}
        {updateSuccess && <Success message="Product Updated Successfully" />}
      </div>
    ) : type === "search" ? (
      <div>{notification && <Error message={notification_msg} />}</div>
    ) : (
      ""
    );

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <h1>Manage Product</h1>
        </Col>
        <hr />
        <br />
        <br />
        <Col xs={12} md={6}>
          <Form onSubmit={submitHandler}>
            <Form.Group
              controlId="sku"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "76px",
              }}
            >
              <Form.Label>SKU</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter SKU ID"
                value={skuId}
                onChange={(e) => setSkuID(e.target.value)}
              ></Form.Control>
              <Image
                src={search}
                alt="search_icon"
                style={{ width: "40px", height: "40px", cursor: "pointer" }}
                onClick={searchHandler}
              />
            </Form.Group>
            <br />
            <Form.Group
              controlId="product_name"
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br />
            <Form.Group
              controlId="cost"
              style={{
                display: "flex",
                gap: "74px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Form.Label>Cost</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Cost"
                value={cost}
                onChange={(e) => setCost(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button type="submit" variant="primary">
                Add New Product
              </Button>
              <Button variant="success" onClick={updateHandler}>
                Update Existing Product
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      {content}
    </Container>
  );
}

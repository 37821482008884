export const ADD_PRODUCT_REQUEST = "product/ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "product/ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "product/ADD_PRODUCT_FAIL";

export const VIEW_PRODUCT_REQUEST = "product/VIEW_PRODUCT_REQUEST";
export const VIEW_PRODUCT_SUCCESS = "product/VIEW_PRODUCT_SUCCESS";
export const VIEW_PRODUCT_FAIL = "product/VIEW_PRODUCT_FAIL";

export const UPDATE_PRODUCT_REQUEST = "product/UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "product/UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "product/UPDATE_PRODUCT_FAIL";

export const LIST_SKU_PRODUCT_REQUEST = "product/LIST_SKU_PRODUCT_REQUEST";
export const LIST_SKU_PRODUCT_SUCCESS = "product/LIST_SKU_PRODUCT_SUCCESS";
export const LIST_SKU_PRODUCT_FAIL = "product/LIST_SKU_PRODUCT_FAIL";

import {
  ADD_PRODUCT_SUCCESS,
  LIST_SKU_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  VIEW_PRODUCT_SUCCESS,
} from "./actionType";

export const addProduct = (data) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const viewProduct = (data) => {
  return {
    type: VIEW_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const updateProduct = (data) => {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const listSKUProduct = (data) => {
  return {
    type: LIST_SKU_PRODUCT_SUCCESS,
    payload: data,
  };
};

import React, { useEffect, useState } from "react";
import { Navbar, Image, Nav, Container, NavDropdown } from "react-bootstrap";
import logo from "../assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import "../App.css";
import avatar from "../assets/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/user/actions";
export default function Header() {
  const [navAvatar, setNavAvatar] = useState(false);

  const login = useSelector((state) => state.login);
  const { userInfo } = login || {};
  let user_name = "";
  if (userInfo !== undefined && userInfo !== null) {
    user_name = userInfo.first_name + " " + userInfo.last_name;
  }
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo) {
      setNavAvatar(true);
    }
  }, [pathname, setNavAvatar, userInfo]);
  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout);
    setNavAvatar(false);
  };
  return (
    <>
      {navAvatar ? (
        <Navbar className="navbar-custom" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <Image
                className="h-10"
                src={logo}
                alt="Valley Hatghery"
                style={{ width: "160px", height: "50px" }}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/run_report">Run Report</Nav.Link>
                <Nav.Link href="/loss_report">Report Loss</Nav.Link>
                <Nav.Link href="/manage_report">Manage Product</Nav.Link>
                <Nav.Link href="/sku_list">SKU </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <NavDropdown
              title={
                <div className="mx-3">
                  <img
                    className="thumbnail-image"
                    src={avatar}
                    alt="user pic"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              }
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item>
                <Link to="/" style={{ textDecoration: "none" }}>
                  {user_name}
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={logoutHandler}>
                <i className="fa fa-sign-out"></i> Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Container>
        </Navbar>
      ) : (
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <Link to="/">
            <Image
              className="h-10"
              src={logo}
              alt="Valley Hatghery"
              style={{ width: "350px", height: "100px" }}
            />
          </Link>
        </div>
      )}
    </>
  );
}

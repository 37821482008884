import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import fetchViewReportLoss from "../../redux/thunk/fetchViewReportLoss";
import Report from "./Report";
import Error from "../ui/Error";

export default function RunReport() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const { userInfo } = login || {};
  const reportLossView = useSelector((state) => state.reportLossView);
  const { success, lossReport, isError, error } = reportLossView || {};
  const dispatch = useDispatch();

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const reportView = () => {
    dispatch(fetchViewReportLoss(startDate, endDate));
  };
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <h1>Run Report</h1>
        </Col>
        <hr />
        <br />
        <br />
        <Col xs={12} md={12} style={{ textAlign: "center" }}>
          <label className="date-level">Start Date:</label>
          <input
            className="date-input"
            type="date"
            value={startDate}
            onChange={(e) => handleStartDateChange(e.target.value)}
            required
          />
          <label className="date-level">End Date:</label>
          <input
            className="date-input"
            type="date"
            value={endDate}
            onChange={(e) => handleEndDateChange(e.target.value)}
            required
          />
        </Col>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Col xs={12} md={12} style={{ textAlign: "center" }}>
          <Button variant="outline-info" size="md" onClick={reportView}>
            run Report
          </Button>
        </Col>
      </Row>

      <br />
      {success && (
        <Report
          startDate={startDate}
          endDate={endDate}
          lossReport={lossReport}
        />
      )}
      {isError && <Error message={error} />}
    </Container>
  );
}

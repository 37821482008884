import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReportPart from "../ReportPart";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { convertDate } from "../convertDate";

export default function Report({ startDate, endDate, lossReport }) {
  const totalCost = lossReport.reduce(
    (sum, report) => sum + report.total_cost,
    0
  );
  console.log(totalCost);
  const generatePDF = () => {
    const input = document.getElementById("report");
    if (input) {
      html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          let heightLeft = pdfHeight;
          let position = 0;

          pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
          heightLeft -= pdf.internal.pageSize.getHeight();

          while (heightLeft >= 0) {
            position = heightLeft - pdfHeight;
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
            heightLeft -= pdf.internal.pageSize.getHeight();
          }

          pdf.save("report.pdf");
        })
        .catch((err) => {
          console.error("Failed to generate PDF", err);
        });
    } else {
      console.error("Element not found");
    }
  };
  return (
    <Container>
      <div style={{ textAlign: "center" }}>
        <Button
          variant="info"
          style={{ textAlign: "center", margin: "40px 0px" }}
          onClick={generatePDF}
        >
          Download PDF
        </Button>
      </div>
      <div id="report">
        <Row>
          <Col xs={12} md={12}>
            <h3 style={{ textAlign: "center", margin: "20px 0px" }}>
              Valley Hatchery - Loss Report {convertDate(startDate)} to{" "}
              {convertDate(endDate)}
            </h3>
          </Col>
        </Row>
        <hr />
        <br />
        <p
          style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
        >
          Grand Total Credit Issued for Period: ${totalCost.toFixed(2)}
        </p>
        {lossReport?.map((report, index) => (
          <ReportPart key={index} report={report} />
        ))}
      </div>
    </Container>
  );
}

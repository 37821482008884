import { login } from "../user/actions";
import { USER_LOGIN_FAIL, USER_LOGIN_REQUEST } from "../user/actionType";
import axios from "./utils/axios";

const fetchUserLogin = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_LOGIN_REQUEST });
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      //   const convertEmail = email.split("@")[0];
      const { data } = await axios.post(
        "api/auth/login/",
        { email: email, password: password },
        config
      );
      dispatch(login(data));
      localStorage.setItem(
        "valley_hatghery_mlr_userInfo",
        JSON.stringify(data)
      );
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
};

export default fetchUserLogin;

import { combineReducers } from "redux";
import { userLoginReducer } from "./user/userReducer";
import {
  reportLossAddReducer,
  reportLossUpdateReducer,
  reportLossViewReducer,
} from "./report/reportReducer";
import {
  productAddReducer,
  productUpdateReducer,
  productViewReducer,
  skuProductListReducer,
} from "./product/productReducer";
const rootReducer = combineReducers({
  login: userLoginReducer,
  reportLossAdd: reportLossAddReducer,
  reportLossView: reportLossViewReducer,
  reportLossUpdate: reportLossUpdateReducer,
  addProduct: productAddReducer,
  viewProduct: productViewReducer,
  updateProduct: productUpdateReducer,
  skuProductList: skuProductListReducer,
});

export default rootReducer;

import { listSKUProduct } from "../product/actions";
import {
  LIST_SKU_PRODUCT_FAIL,
  LIST_SKU_PRODUCT_REQUEST,
} from "../product/actionType";
import axios from "./utils/axios";

const fetchSKUProductList = async (dispatch, getState) => {
  try {
    dispatch({ type: LIST_SKU_PRODUCT_REQUEST });
    const {
      login: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`api/report/get_sku_list/`, config);
    dispatch(listSKUProduct(data));
  } catch (error) {
    dispatch({
      type: LIST_SKU_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
export default fetchSKUProductList;

import { addReportLoss } from "../report/actions";
import {
  ADD_REPORT_LOSS_FAIL,
  ADD_REPORT_LOSS_REQUEST,
} from "../report/actionType";
import axios from "./utils/axios";

const fetchAddLossReport = (loss_report) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_REPORT_LOSS_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        "api/report/create_loss_report/",
        loss_report,
        config
      );
      dispatch(addReportLoss(data));
    } catch (error) {
      dispatch({
        type: ADD_REPORT_LOSS_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchAddLossReport;

import {
  ADD_REPORT_LOSS_FAIL,
  ADD_REPORT_LOSS_REQUEST,
  ADD_REPORT_LOSS_SUCCESS,
  UPDATE_REPORT_LOSS_FAIL,
  UPDATE_REPORT_LOSS_REQUEST,
  UPDATE_REPORT_LOSS_SUCCESS,
  VIEW_REPORT_LOSS_FAIL,
  VIEW_REPORT_LOSS_REQUEST,
  VIEW_REPORT_LOSS_SUCCESS,
} from "./actionType";

export const reportLossAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_REPORT_LOSS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_REPORT_LOSS_SUCCESS:
      return {
        loading: false,
        lossReport: action.payload,
        success: true,
        isError: false,
      };
    case ADD_REPORT_LOSS_FAIL:
      return {
        loading: false,
        lossReport: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const reportLossViewReducer = (state = [], action) => {
  switch (action.type) {
    case VIEW_REPORT_LOSS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VIEW_REPORT_LOSS_SUCCESS:
      return {
        loading: false,
        lossReport: action.payload,
        success: true,
        isError: false,
      };
    case VIEW_REPORT_LOSS_FAIL:
      return {
        loading: false,
        lossReport: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const reportLossUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_REPORT_LOSS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_REPORT_LOSS_SUCCESS:
      return {
        loading: false,
        lossReport: action.payload,
        success: true,
        isError: false,
      };
    case UPDATE_REPORT_LOSS_FAIL:
      return {
        loading: false,
        lossReport: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import NotFound from "./components/ui/NotFound";
import Home from "./components/Pages/Home";
import ReportLoss from "./components/Pages/ReportLoss";
import ManageReport from "./components/Pages/ManageReport";
import RunReport from "./components/Pages/RunReport";
import SkuList from "./components/Pages/SkuList";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/run_report" element={<RunReport />} />
        <Route path="/loss_report" element={<ReportLoss />} />
        <Route path="/manage_report" element={<ManageReport />} />
        <Route path="/sku_list" element={<SkuList />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

import { viewReportLoss } from "../report/actions";
import {
  VIEW_REPORT_LOSS_FAIL,
  VIEW_REPORT_LOSS_REQUEST,
} from "../report/actionType";
import axios from "./utils/axios";

const fetchViewReportLoss = (startDate, endDate) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: VIEW_REPORT_LOSS_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `api/report/get_loss_report/${startDate}/${endDate}/`,
        config
      );
      dispatch(viewReportLoss(data));
    } catch (error) {
      dispatch({
        type: VIEW_REPORT_LOSS_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchViewReportLoss;

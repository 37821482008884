export const ADD_REPORT_LOSS_REQUEST = "report/ADD_REPORT_LOSS_REQUEST";
export const ADD_REPORT_LOSS_SUCCESS = "report/ADD_REPORT_LOSS_SUCCESS";
export const ADD_REPORT_LOSS_FAIL = "report/ADD_REPORT_LOSS_FAIL";

export const VIEW_REPORT_LOSS_REQUEST = "report/VIEW_REPORT_LOSS_REQUEST";
export const VIEW_REPORT_LOSS_SUCCESS = "report/VIEW_REPORT_LOSS_SUCCESS";
export const VIEW_REPORT_LOSS_FAIL = "report/VIEW_REPORT_LOSS_FAIL";

export const UPDATE_REPORT_LOSS_REQUEST = "report/UPDATE_REPORT_LOSS_REQUEST";
export const UPDATE_REPORT_LOSS_SUCCESS = "report/UPDATE_REPORT_LOSS_SUCCESS";
export const UPDATE_REPORT_LOSS_FAIL = "report/UPDATE_REPORT_LOSS_FAIL";

import React, { useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import fetchSKUProductList from "../../redux/thunk/fetchSKUProductList";
import Loading from "../ui/Loading";
import Error from "../ui/Error";

export default function SkuList() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const skuProductList = useSelector((state) => state.skuProductList);
  const { loading, isError, error, sku_products } = skuProductList || {};
  const { userInfo } = login || {};
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchSKUProductList);
  }, [navigate, userInfo, dispatch]);
  return (
    <Container>
      {loading && <Loading />}
      {isError && <Error message={error} />}
      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <h1>SKU List</h1>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>SKU ID</th>
            <th>Product Name</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          {sku_products?.map((product, index) => (
            <tr key={product.id}>
              <td>{index + 1}</td>
              <td>{product.sku_id}</td>
              <td>{product.product_name}</td>
              <td>{product.cost}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
